.narrative-page {
    /* border: 1px solid #ccc; */
    padding: 10px;
    width: auto;
    margin: auto;
}

.content-size {
    font-size: 24px !important;
}

.busines-title {
    font-size: 28px !important;
}

.narrative-title {
    font-size: 28px !important;
    font-weight: bold;
}

.page-break {
    /* display: block; */
    /* padding: 10px 0 10px 0; */
    page-break-before: always;
}

.repayment-print {
    zoom: 85%;
}

.income-statement-print {
    zoom: 90%;
}

.income-sale-print {
    zoom: 85%;
}

.income-fixed-print {
    zoom: 80%;
}

.income-prod-print {
    zoom: 83%;
}

.content-list .table th {
    font-size: 1rem;
}

.content-list li::marker {
    font-size: 22px;
    font-weight: bold;
}

.content-list p, .content-list li {
    font-size: 22px;
}

.footer-box td {

        vertical-align: top;
  
}

@media print {

    div.fix-break-print-page {
        page-break-inside: avoid;
    }

    @page {
        size: 210mm 297mm;
        /* Chrome sets own margins, we change these printer settings */
        margin: 15mm 10mm 10mm 10mm
        
    }

    .narrative-page {
        padding: 5px !important;
        padding-left: 15px !important;
        margin: 0 !important;
    }

    .cashFlow-print {
        display: block;
    }

    .repayment-print {
        display: block;
    }

    .busines-title {
        margin-top: 50%;
        margin-left: 50%;
        transform: translate(-50%, -50%);
    }

    .print-hide {
        display: block !important;
    }

    #footer {
        margin-top: 200px;
    }
    .page-bottom {
        text-align: center;
        margin-top: 400px;
    }
    .page-1a{
        padding-top: 25%;
    }
}